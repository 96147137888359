<template>
  <div :class="wrapperClasses" :data-qa="question.key">
    <div
      v-for="option in question.options"
      :key="option.inputId"
      :class="[optionClasses]"
    >
      <RevRadio
        v-if="variant === 'radio'"
        :id="option.inputId"
        v-model="radioModel"
        class="mx-4"
        :data-qa="`${question.key}-${option.value}`"
        :image="option.icon"
        :image-props="{ height: 24, width: 43, alt: '' }"
        :value="option.value"
        variant="full"
        @update:model-value="question.onChange(question.key)"
      >
        <template #label>
          <div class="whitespace-nowrap">{{ option.label }}</div>
        </template>
        <template #description v-if="option.description">
          {{ option.description }}
        </template>
      </RevRadio>
      <RevPicker
        v-if="variant === 'picker'"
        class="h-full w-full"
        :data-qa="`${question.key}-${option.value}`"
        :description="option.description"
        :label="option.label"
        :model-value="radioModel === option.value"
        @update:model-value="handlePickerClick(question.key, option.value)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { RevPicker } from '@ds/components/Picker'
import { RevRadio } from '@ds/components/Radio'

import type { RenderQuestion } from './QuestionsForm.vue'

const props = defineProps<{
  question: RenderQuestion
  variant: 'picker' | 'radio'
}>()

const radioModel = defineModel<string | false>('radioModel', {
  required: true,
})

function handlePickerClick(key: string, value: string) {
  radioModel.value = value

  props.question.onChange(key)
}

const hasDescription = computed(() => {
  return props.question.options.some((option) => option.description)
})

const wrapperClasses = computed(() => {
  const displayClasses =
    props.variant === 'picker'
      ? `grid grid-cols-${props.question.optionsColumnsNumber} gap-x-8 gap-y-12`
      : 'flex -mx-1 gap-y-22'

  const descriptionClasses = hasDescription.value ? 'flex-col' : 'flex-wrap'

  return `${descriptionClasses} ${displayClasses}`
})

const optionClasses = computed(() => {
  return hasDescription.value || props.variant === 'picker'
    ? 'w-full'
    : 'w-full md:w-1/2'
})
</script>
