import { createHttpEndpoint } from '../../utils'
import type { BuybackModelImage } from '../models'

export type QuestionInputType =
  | 'binary'
  | 'checkbox'
  | 'radioline'
  | 'select'
  | 'textInput'

export type InfoBlockIcon = 'search' | 'battery' | 'shield' | 'info'

export interface InfoBlock {
  icon: InfoBlockIcon
  title: string
  paragraphs: Array<string>
}

export interface Option {
  label: string
  value: string
  selected: boolean
  image: string | null
  icon: BuybackModelImage | null
  description: string
  maxPriceEstimate: number | null
}

export interface Question {
  key: string
  title: string
  subTitleList: Array<string> | null
  lastQuestion: boolean
  type: QuestionInputType
  placeholder: string | null
  options: Array<Option>
  infoBlock: InfoBlock | null
  optionsColumnsNumber: number
}

export interface Step {
  key: string
  label: string
  title: string
  completed: boolean
  active: boolean
  infoBlock: InfoBlock | null
}

export interface SummaryItem {
  question: string
  answer: string
}

export interface FunnelStep {
  step: Step | null
  questions: Array<Question>
}

export interface GetQuestionsResponse {
  funnel: Array<FunnelStep>
  summary: Array<SummaryItem>
}

export type GetQuestionsKind = 'regular' | 'swap'

/**
 * Questions funnel V3
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buyback-funnel-api/definition#/Public/get_questions}
 */

export const getQuestionsV3 = createHttpEndpoint<GetQuestionsResponse>({
  method: 'GET',
  operationId: 'getQuestionsV3',
  path: '/buyback-funnel/api/v1/funnel/:kind/questions',
})
