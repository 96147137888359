export default {
  continue: {
    id: 'buyback_funnel_device_continue',
    defaultMessage: 'Continue',
  },
  seeOffer: {
    id: 'buyback_funnel_device_see_offer',
    defaultMessage: 'See our offer',
  },
}
